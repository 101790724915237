import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Icon from "../utilities/icons"
import { SideInfoBox } from "../components/styles/SideInfoBox"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div class="standard-page-container">
      <div className="inner-container">
        <div className="two-column-layout-wrapper">
          <div className="two-column-layout-item_wide">
            <h1>404 - NOT FOUND</h1>
            <p>
              Go to the <Link to="/">Homepage</Link>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
